export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0];

export const dictionary = {
		"/": [6],
		"/policies/[pageName]": [~17],
		"/[location]": [~7,[2]],
		"/[location]/account": [13,[2]],
		"/[location]/account/my-pets": [~14,[2,5]],
		"/[location]/checkout": [15,[2]],
		"/[location]/checkout/confirmation": [16,[2]],
		"/[location]/[service]": [8,[2,3],[,4]],
		"/[location]/[service]/select-a-date": [9,[2,3],[,4]],
		"/[location]/[service]/select-a-package": [~10,[2,3],[,4]],
		"/[location]/[service]/select-a-room": [~11,[2,3],[,4]],
		"/[location]/[service]/select-start-date": [12,[2,3],[,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';